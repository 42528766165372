import Menu from "../components/Menu"



const About = () => {
    return (
        <>
        <Menu/>
        <h1>O Nas</h1>
        </>
    )
}
export default About;