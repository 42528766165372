import React from 'react';
import {
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardFooter,
  MDBRow,
  MDBCol
} from 'mdb-react-ui-kit';
import logo from '../images/logoColor.png'


const CardFingerSweet = () => {

  const color = {
    color:"white",
    fontSize:"15px"
  }

  return (
    <MDBRow className='row-cols-1 row-cols-md-4 g-7'>
      <MDBCol>
        <MDBCard className='h-100'>
          <MDBCardImage
            src={logo}
            alt='meatballs'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Słodkie</MDBCardTitle>
            <MDBCardText>
            Mini tiramisu
            </MDBCardText>
          <MDBCardTitle>7.50 zł</MDBCardTitle>
          </MDBCardBody>
          <MDBCardFooter className="text-center" style={color}>Minimalna ilość zamówienia to 12 sztuk</MDBCardFooter>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100'>
          <MDBCardImage
            src={logo}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Słodkie</MDBCardTitle>
            <MDBCardText>
            Mini Pana cotta z musem malinowym
            </MDBCardText>
          <MDBCardTitle>7.50 zł</MDBCardTitle>
          </MDBCardBody>
          <MDBCardFooter className="text-center" style={color}>Minimalna ilość zamówienia to 12 sztuk</MDBCardFooter>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100'>
          <MDBCardImage
            src={logo}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Słodkie</MDBCardTitle>
            <MDBCardText>
            Serniczki oreo
            </MDBCardText>
            <MDBCardTitle>9.50 zł</MDBCardTitle>
          </MDBCardBody>
          <MDBCardFooter className="text-center" style={color}>Minimalna ilość zamówienia to 12 sztuk</MDBCardFooter>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100'>
          <MDBCardImage
            src={logo}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Słodkie</MDBCardTitle>
            <MDBCardText>
            Deserek z waniliowym z musem truskawkowym
            </MDBCardText>
            <MDBCardTitle>8.50 zł</MDBCardTitle>
          </MDBCardBody>
          <MDBCardFooter className="text-center" style={color}>Minimalna ilość zamówienia to 12 sztuk</MDBCardFooter>
        </MDBCard>
      </MDBCol>
      {/* <MDBCol>
        <MDBCard className='h-100'>
          <MDBCardImage
            src={logo}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Słodkie</MDBCardTitle>
            <MDBCardText>
            Serniczek z orzechami i owcowami jagodowymi 
            </MDBCardText>
            <MDBCardTitle>10.50 zł</MDBCardTitle>
          </MDBCardBody>
          <MDBCardFooter className="text-center" style={color}>Minimalna ilość zamówienia to 12 sztuk</MDBCardFooter>
        </MDBCard>
      </MDBCol> */}
      <MDBCol>
        <MDBCard className='h-100'>
          <MDBCardImage
            src={logo}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Słodkie</MDBCardTitle>
            <MDBCardText>
            Mini gniazdka z rumem 
            </MDBCardText>
            <MDBCardTitle>6.50 zł</MDBCardTitle>
          </MDBCardBody>
          <MDBCardFooter className="text-center" style={color}>Minimalna ilość zamówienia to 12 sztuk</MDBCardFooter>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100'>
          <MDBCardImage
            src={logo}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Słodkie</MDBCardTitle>
            <MDBCardText>
            Babeczki z nutellą 
            </MDBCardText>
            <MDBCardTitle>7.50 zł</MDBCardTitle>
          </MDBCardBody>
          <MDBCardFooter className="text-center" style={color}>Minimalna ilość zamówienia to 12 sztuk</MDBCardFooter>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100'>
          <MDBCardImage
            src={logo}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Słodkie</MDBCardTitle>
            <MDBCardText>
            Babeczki marchewkowe z orzechami
            </MDBCardText>
            <MDBCardTitle>8.50 zł</MDBCardTitle>
          </MDBCardBody>
          <MDBCardFooter className="text-center" style={color}>Minimalna ilość zamówienia to 12 sztuk</MDBCardFooter>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100'>
          <MDBCardImage
            src={logo}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Słodkie</MDBCardTitle>
            <MDBCardText>
            Babeczki z jabłkami i daktylami  
            </MDBCardText>
            <MDBCardTitle>8.50 zł</MDBCardTitle>
          </MDBCardBody>
          <MDBCardFooter className="text-center" style={color}>Minimalna ilość zamówienia to 12 sztuk</MDBCardFooter>
        </MDBCard>
      </MDBCol>
    </MDBRow>
  );
}
export default CardFingerSweet;