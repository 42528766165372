import React from 'react';
import {
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardFooter,
  MDBRow,
  MDBCol
} from 'mdb-react-ui-kit';
import finger from '../images/finger.webp'
import fingerOne from '../images/fingerOne.webp'
import fingerTwo from '../images/fingerTwo.webp'
import fingerThree from '../images/fingerThree.webp'


const CardFingerKoreczki = () => {

  const color = {
    color:"white",
    fontSize:"15px"
  }

  return (

<MDBRow className='row-cols-1 row-cols-md-4 g-7'>
      <MDBCol>
        <MDBCard className='h-100'>
          <MDBCardImage
            src={finger}
            alt='meatballs'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Koreczki</MDBCardTitle>
            <MDBCardText>
            Pikantne klopsiki wieprzowo – wołowe z ogórkiem konserwowym 
            </MDBCardText>
          <MDBCardTitle>7 zł</MDBCardTitle>
          </MDBCardBody>
          <MDBCardFooter className="text-center" style={color}>Minimalna ilość zamówienia to 12 sztuk</MDBCardFooter>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100'>
          <MDBCardImage
            src={fingerThree}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Koreczki</MDBCardTitle>
            <MDBCardText>
            Oliwka, mozzarella, pomidor cherry
            </MDBCardText>
          <MDBCardTitle>6 zł</MDBCardTitle>
          </MDBCardBody>
          <MDBCardFooter className="text-center" style={color}>Minimalna ilość zamówienia to 12 sztuk</MDBCardFooter>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100'>
          <MDBCardImage
            src={fingerTwo}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Koreczki</MDBCardTitle>
            <MDBCardText>
            Łosoś, ser kozi, pomidor cherry 
            </MDBCardText>
            <MDBCardTitle>6,5 zł</MDBCardTitle>
          </MDBCardBody>
          <MDBCardFooter className="text-center" style={color}>Minimalna ilość zamówienia to 12 sztuk</MDBCardFooter>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100'>
          <MDBCardImage
            src={fingerOne}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Koreczki</MDBCardTitle>
            <MDBCardText>
            Camembert, szynka parmeńska, winogrono 
            </MDBCardText>
            <MDBCardTitle>7 zł</MDBCardTitle>
            <MDBCardFooter className="text-center" style={color}>Minimalna ilość zamówienia to 12 sztuk</MDBCardFooter>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      </MDBRow>
  );
};
export default CardFingerKoreczki;