import CardFingerDry from "../components/CardFingerDry"
import CardFingerKoreczki from "../components/CardFingerKoreczki"
import Menu from "../components/Menu"
const FingerDry = () => {
    return (
<>
<Menu/>
<CardFingerKoreczki/>
<CardFingerDry/>
</>
    )
}
export default FingerDry;