import Menu from "../components/Menu";
import GalleryComp from "../components/GalleryComp";

const Gallery = () => {
  return (
    <>
      <Menu />
      <GalleryComp />
    </>
  );
};
export default Gallery;
