import React from 'react';
import {
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardFooter,
  MDBRow,
  MDBCol
} from 'mdb-react-ui-kit';


import burger from '../images/burger.webp'
import tartaletkiS from '../images/tartaletkiS.webp'
import tartaletki from '../images/tartaletki.webp'
import tartaletkiO from '../images/tartaletkiO.webp'
import tacos from '../images/tacos.webp'
import tortilaCardDry from '../images/tortilaCardDry.webp'
import Szaszlyczki from '../images/Szaszlyczki.webp'
import calzone from '../images/calzone.webp'
import losos from '../images/losos.webp'
import kanapSer from '../images/kanapSer.webp'
import kanapPasz from '../images/kanapPasz.webp'
import kanapLos from '../images/kanapLos.webp'
import kanapPar from '../images/kanapPar.webp'
import kanapSalam from '../images/kanapSalam.webp'
import hotdog from '../images/hotdog.webp'
import tortilaOneCardDry from '../images/tortilaOneCardDry.webp'
import tortilaTwoCardDry from '../images/tortilaTwoCardDry.webp'
import croisPar from '../images/croisPar.webp'
import croisPom from '../images/croisPom.webp'
import croisLos from '../images/croisLos.webp'

const CardFingerDry = () => {

  const color = {
    color:"white",
    fontSize:"15px"
  }

  return (
    <>
    <MDBRow className='row-cols-1 row-cols-md-4 g-7'>
      <MDBCol>
        <MDBCard className='h-100'>
          <MDBCardImage
            src={croisPar}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Wytrawne</MDBCardTitle>
            <MDBCardText>
            Croissant z szynką parmeńską, pesto pomidorowym, ogórkiem, rukola, sałatą 
            </MDBCardText>
            <MDBCardTitle>10 zł</MDBCardTitle>
          </MDBCardBody>
          <MDBCardFooter className="text-center" style={color}>Minimalna ilość zamówienia to 12 sztuk</MDBCardFooter>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100'>
          <MDBCardImage
            src={croisPom}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Wytrawne</MDBCardTitle>
            <MDBCardText>
            Croissant z mozzarella, pomidorem, rukola, oliwkami, sałata 
            </MDBCardText>
            <MDBCardTitle>10 zł</MDBCardTitle>
          </MDBCardBody>
          <MDBCardFooter className="text-center" style={color}>Minimalna ilość zamówienia to 12 sztuk</MDBCardFooter>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100'>
          <MDBCardImage
            src={croisLos}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Wytrawne</MDBCardTitle>
            <MDBCardText>
            Croissant z łososiem wędzonym, kaparami, szpinakiem, sałatą 
            </MDBCardText>
            <MDBCardTitle>10 zł</MDBCardTitle>
          </MDBCardBody>
          <MDBCardFooter className="text-center" style={color}>Minimalna ilość zamówienia to 12 sztuk</MDBCardFooter>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100'>
          <MDBCardImage
            src={burger}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Wytrawne</MDBCardTitle>
            <MDBCardText>
            Mini burger
            </MDBCardText>
            <MDBCardTitle>10 zł</MDBCardTitle>
          </MDBCardBody>
          <MDBCardFooter className="text-center" style={color}>Minimalna ilość zamówienia to 12 sztuk</MDBCardFooter>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100'>
          <MDBCardImage
            src={tartaletkiS}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Wytrawne</MDBCardTitle>
            <MDBCardText>
            Tartaletki z szpinakiem, fetą i suszonymi pomidorami 
            </MDBCardText>
            <MDBCardTitle>7 zł</MDBCardTitle>
          </MDBCardBody>
          <MDBCardFooter className="text-center" style={color}>Minimalna ilość zamówienia to 12 sztuk</MDBCardFooter>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100'>
          <MDBCardImage
            src={tartaletkiO}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Wytrawne</MDBCardTitle>
            <MDBCardText>
            Tartaletki z pastą drobiową
            </MDBCardText>
            <MDBCardTitle>7 zł</MDBCardTitle>
          </MDBCardBody>
          <MDBCardFooter className="text-center" style={color}>Minimalna ilość zamówienia to 12 sztuk</MDBCardFooter>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100'>
          <MDBCardImage
            src={tartaletki}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Wytrawne</MDBCardTitle>
            <MDBCardText>
            Tartaletka z pastą łososiową
            </MDBCardText>
            <MDBCardTitle>8 zł</MDBCardTitle>
          </MDBCardBody>
          <MDBCardFooter className="text-center" style={color}>Minimalna ilość zamówienia to 12 sztuk</MDBCardFooter>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100'>
          <MDBCardImage
            src={tortilaTwoCardDry}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Wytrawne</MDBCardTitle>
            <MDBCardText>
            Naleśniki szpinakowe z łososiem i warzywami
            </MDBCardText>
            <MDBCardTitle>6.50 zł</MDBCardTitle>
          </MDBCardBody>
          <MDBCardFooter className="text-center" style={color}>Minimalna ilość zamówienia to 12 sztuk</MDBCardFooter>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100'>
          <MDBCardImage
            src={tacos}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Wytrawne</MDBCardTitle>
            <MDBCardText>
            Tacos z pieczonym indykiem i salsą pomidorową
            </MDBCardText>
            <MDBCardTitle>9.50 zł</MDBCardTitle>
          </MDBCardBody>
          <MDBCardFooter className="text-center" style={color}>Minimalna ilość zamówienia to 12 sztuk</MDBCardFooter>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100'>
          <MDBCardImage
            src={tortilaCardDry}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Wytrawne</MDBCardTitle>
            <MDBCardText>
            Tortilla z szynka parmeńska, warzywami
            </MDBCardText>
            <MDBCardTitle>5.50 zł</MDBCardTitle>
          </MDBCardBody>
          <MDBCardFooter className="text-center" style={color}>Minimalna ilość zamówienia to 12 sztuk</MDBCardFooter>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100'>
          <MDBCardImage
            src={tortilaOneCardDry}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Wytrawne</MDBCardTitle>
            <MDBCardText>
            Tortilla z salami picantte i warzywami
            </MDBCardText>
            <MDBCardTitle>5.50 zł</MDBCardTitle>
          </MDBCardBody>
          <MDBCardFooter className="text-center" style={color}>Minimalna ilość zamówienia to 12 sztuk</MDBCardFooter>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100'>
          <MDBCardImage
            src={hotdog}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Wytrawne</MDBCardTitle>
            <MDBCardText>
            Strips dog z polędwiczką drobiową i warzywami 
            </MDBCardText>
            <MDBCardTitle>9.50 zł</MDBCardTitle>
          </MDBCardBody>
          <MDBCardFooter className="text-center" style={color}>Minimalna ilość zamówienia to 12 sztuk</MDBCardFooter>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100'>
          <MDBCardImage
            src={Szaszlyczki}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Wytrawne</MDBCardTitle>
            <MDBCardText>
            Mini szaszłyki drobiowe z cukinią, pieczarkami, papryką
            </MDBCardText>
            <MDBCardTitle>10 zł</MDBCardTitle>
          </MDBCardBody>
          <MDBCardFooter className="text-center" style={color}>Minimalna ilość zamówienia to 12 sztuk</MDBCardFooter>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100'>
          <MDBCardImage
            src={calzone}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Wytrawne</MDBCardTitle>
            <MDBCardText>
            Mini calzone z ciasta francuskiego z mozzarellą i bazylią
            </MDBCardText>
            <MDBCardTitle>8.50 zł</MDBCardTitle>
          </MDBCardBody>
          <MDBCardFooter className="text-center" style={color}>Minimalna ilość zamówienia to 12 sztuk</MDBCardFooter>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100'>
          <MDBCardImage
            src={losos}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Wytrawne</MDBCardTitle>
            <MDBCardText>
            Łosoś w cieście francuskim i warzywami
            </MDBCardText>
            <MDBCardTitle>9.50 zł</MDBCardTitle>
          </MDBCardBody>
          <MDBCardFooter className="text-center" style={color}>Minimalna ilość zamówienia to 12 sztuk</MDBCardFooter>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100'>
          <MDBCardImage
            src={kanapSer}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Wytrawne</MDBCardTitle>
            <MDBCardText>
            Kanapeczki dekoracyjne z serami
            </MDBCardText>
            <MDBCardTitle>6.50 zł</MDBCardTitle>
          </MDBCardBody>
          <MDBCardFooter className="text-center" style={color}>Minimalna ilość zamówienia to 12 sztuk</MDBCardFooter>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100'>
          <MDBCardImage
            src={kanapPasz}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Wytrawne</MDBCardTitle>
            <MDBCardText>
            Kanapeczki dekoracyjne z pasztetem i warzywami
            </MDBCardText>
            <MDBCardTitle>6.50 zł</MDBCardTitle>
          </MDBCardBody>
          <MDBCardFooter className="text-center" style={color}>Minimalna ilość zamówienia to 12 sztuk</MDBCardFooter>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100'>
          <MDBCardImage
            src={kanapPar}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Wytrawne</MDBCardTitle>
            <MDBCardText>
            Kanapeczki dekoracyjne z szynką parmeńską
            </MDBCardText>
            <MDBCardTitle>7 zł</MDBCardTitle>
          </MDBCardBody>
          <MDBCardFooter className="text-center" style={color}>Minimalna ilość zamówienia to 12 sztuk</MDBCardFooter>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100'>
          <MDBCardImage
            src={kanapSalam}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Wytrawne</MDBCardTitle>
            <MDBCardText>
            Kanapeczki dekoracyjne z salami picante
            </MDBCardText>
            <MDBCardTitle>7 zł</MDBCardTitle>
          </MDBCardBody>
          <MDBCardFooter className="text-center" style={color}>Minimalna ilość zamówienia to 12 sztuk</MDBCardFooter>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100'>
          <MDBCardImage
            src={kanapLos}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Wytrawne</MDBCardTitle>
            <MDBCardText>
            Kanapeczki dekoracyjne z łososiem wędzonym, kaparami
            </MDBCardText>
            <MDBCardTitle>7 zł</MDBCardTitle>
          </MDBCardBody>
          <MDBCardFooter className="text-center" style={color}>Minimalna ilość zamówienia to 12 sztuk</MDBCardFooter>
        </MDBCard>
      </MDBCol>
    </MDBRow>

    </>
  );
}
export default CardFingerDry;