import CardFingerSweet from "../components/CardFingerSweet"
import Menu from "../components/Menu"

const FingerSweet = () => {

    return (

<>
<Menu/>
<CardFingerSweet/>
</>

    )
}
export default FingerSweet;