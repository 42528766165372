import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import Home from '../src/pages/Home'
import About from '../src/pages/About';
import Gallery from '../src/pages/Gallery';
import Offers from '../src/pages/Offers';
import FingerDrySweet from './pages/FingerDrySweet';
import FingerDry from './pages/FingerDry';
import FingerSweet from './pages/FingerSweet';
import Sets from './pages/Sets';
import './App.css';



function App() {
  return (
    <Router>
   <div className="App">
      <Routes>
      <Route path="/" exact element={<Home/>}/>
      <Route path="/About" element={<About/>}/>
      <Route path="/Promo" element={<Offers/>}/>
      <Route path="/Galeria" element={<Gallery/>}/>
      <Route path="/FingerDrySweet" element={<FingerDrySweet/>}/>
      <Route path="/FingerDry" element={<FingerDry/>}/>
      <Route path="/FingerSweet" element={<FingerSweet/>}/>
      <Route path="/Sets" element={<Sets/>}/>
      </Routes>
    </div>
    </Router>


  );
}

export default App;
