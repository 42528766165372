import React from 'react';
import FingerSD from '../components/FingerSD';
import Menu from '../components/Menu';

export default function App() {
  return (
    <>
    <Menu/>
    <FingerSD/>
    </>
  );
}