import logo from '../images/logoColor.png'
import { CardGroup, Card, CardImg, CardBody,CardTitle, Button, NavLink } from 'reactstrap';
import ModalMenuCardMeat from './ModalMenuCardMeat';
import ModalMenuCardSalad from './ModalMenuCardSalad';
import fingerFood from '../images/fingerFood.webp'

const Cards = () => {

return (

    <CardGroup>
  <Card>
    <CardImg
      alt="Card image cap"
      src={fingerFood}
      top
      width="100%"
    />
    <CardBody>
      <CardTitle tag="h5">
      Finger Food
      </CardTitle>
      <Button color="danger">
       <NavLink  href="/FingerDrySweet/">SPRAWDŹ</NavLink>
      </Button>
    </CardBody>
  </Card>
  <Card>
    <CardImg
      alt="Card image cap"
      src={logo}
      top
      width="100%"
    />
    <CardBody>
      <CardTitle tag="h5">
      Dania Mięsne
      </CardTitle>
      <ModalMenuCardMeat/>
    </CardBody>
  </Card>
  <Card>
    <CardImg
      alt="Card image cap"
      src={logo}
      top
      width="100%"
    />
    <CardBody>
      <CardTitle tag="h5">
      Salatki i Dodatki 
      </CardTitle>
      <ModalMenuCardSalad/>
    </CardBody>
  </Card>
  <Card>
    <CardImg
      alt="Card image cap"
      src={logo}
      top
      width="100%"
    />
    <CardBody>
      <CardTitle tag="h5">
      Zestawy
      </CardTitle>
      <Button color="danger">
       <NavLink  href="/Sets/">SPRAWDŹ</NavLink>
      </Button>
    </CardBody>
  </Card>
</CardGroup>
)
}
export default Cards;