import {
    MDBCard,
    MDBCardImage,
    MDBCardBody,
    MDBCardTitle,
    MDBRow,
    MDBCol,
    MDBNavbarLink,
    MDBBtn
  } from 'mdb-react-ui-kit';
  import wytrawne from '../images/wytrawne.webp';
  import Slodkie from '../images/Slodkie.webp';


const FingerSD = () => {


    return (
        <>
<MDBRow className='row-cols-1 row-cols-md-4 g-2'>
<MDBCol >
 <MDBCard >
  </MDBCard> 
</MDBCol>
<MDBCol>
  <MDBCard >
    <MDBCardImage
      src={wytrawne}
      alt='...'
      position='top'
    />
    <MDBCardBody>
      <MDBCardTitle>Wytrawne</MDBCardTitle>
    <MDBBtn color='danger'>
        <MDBNavbarLink href='/FingerDry/'>SPRAWDŹ</MDBNavbarLink>
    </MDBBtn>
    </MDBCardBody>
  </MDBCard>
</MDBCol>
<MDBCol>
  <MDBCard>
    <MDBCardImage
      src={Slodkie}
      alt='...'
      position='top'
    />
    <MDBCardBody>
      <MDBCardTitle>Słodkie</MDBCardTitle>
      <MDBBtn color='danger'>
        <MDBNavbarLink href='/FingerSweet/'>SPRAWDŹ</MDBNavbarLink>
    </MDBBtn>
    </MDBCardBody>
  </MDBCard>
</MDBCol>
<MDBCol>
  <MDBCard >
  </MDBCard>
</MDBCol> 
</MDBRow>
</>
    )
}
export default FingerSD;