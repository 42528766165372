import backgroundN from "../images/backgroundN.webp";
import backgroundOne from "../images/backgroundOne.webp";
import backgroundTwo from "../images/backgroundTwo.webp";
import backgroundThree from "../images/backgroundThree.webp";
import backgroundMOne from "../images/backgroundMOne.webp";
import backgroundMTwo from "../images/backgroundMTwo.webp";
import backgroundMThree from "../images/backgroundMThree.webp";
import gallery from '../images/gallery.webp'
import galleryOne from '../images/galleryOne.webp'
import galleryTwo from '../images/galleryTwo.webp'
import galleryThree from '../images/galleryThree.webp'
import galleryFinger from '../images/galleryFinger.webp'

const Photos = [
    {
      src: backgroundN,
      width: 1600,
      height: 900,
    },
    {
      src: backgroundOne,
      width: 1600,
      height: 900,
    },
    {
      src: backgroundTwo,
      width: 1600,
      height: 900,
    },
    {
      src: backgroundMOne,
      width: 1200,
      height: 1800,
    },
    {
      src: backgroundMTwo,
      width: 1200,
      height: 1900,
    },
    {
      src: backgroundThree,
      width: 1900,
      height: 900,
    },
    {
      src: backgroundMThree,
      width: 1200,
      height: 1900,
    },
    {
      src: gallery,
      width: 1600,
      height: 2000,
    },
    {
      src: galleryOne,
      width: 1600,
      height: 2000,
    },
    {
      src: galleryTwo,
      width: 1600,
      height: 2000,
    },
    {
      src: galleryThree,
      width: 1600,
      height: 2000,
    },
    {
      src: galleryFinger,
      width: 2000,
      height: 1600,
    },

  ];

  export default Photos;
 