import Menu from "../components/Menu";
import Cards from '../components/Cards';

const Offers = () => {

    return(
        <div className="Offers">
        <Menu/>
        <Cards/>
        </div>
    )
}
export default Offers;
