import React from 'react';
import {
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol
} from 'mdb-react-ui-kit';
import galleryThree from '../images/galleryThree.webp'
import AmerSet from '../images/AmerSet.webp'
import ItalSet from '../images/ItalSet.webp'
import TortilaSet from '../images/TortilaSet.webp'

const CardSetMain = () => {
  return (
    <MDBRow className='row-cols-1 row-cols-md-4 g-4'>
      <MDBCol>
        <MDBCard className='h-100'>
          <MDBCardImage
            src={AmerSet}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>American dream</MDBCardTitle>
            <MDBCardText>
            7x mini burger<br/>
            7x strips dog<br/>
            12x Tortilla z salami picante<br/>
            7x tacos z pieczonym indykiem 
            </MDBCardText>
          </MDBCardBody>
         <MDBCardTitle>200 zł</MDBCardTitle>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100'>
          <MDBCardImage
            src={ItalSet}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Italian style</MDBCardTitle>
            <MDBCardText>
            7x Mini calzone z ciasta francuskiego z mozzarellą i bazylią<br/>
            10 x koreczki z Camembert, szynka parmeńska, winogrono<br/>
            12x tortilla z szynką parmeńską i warzywami<br/>
            7x Croissant z mozzarella, pomidorem, rukolą, oliwkami, sałatą
            </MDBCardText>
          </MDBCardBody>
          <MDBCardTitle>190 zł</MDBCardTitle>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100'>
          <MDBCardImage
            src={galleryThree}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Kanapkowy mix - 20 sztuk</MDBCardTitle>
            <MDBCardText>
            Mix kanapek z szynką parmeńską,<br/> konserwową,<br/> serami,<br/> warzywami,<br/> pasztetem
            </MDBCardText>
          </MDBCardBody>
           <MDBCardTitle>100 zł</MDBCardTitle>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100'>
          <MDBCardImage
            src={TortilaSet}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Tortilla set – 30 sztuk</MDBCardTitle>
            <MDBCardText>
            Mix tortrilli z szynką parmeńska,<br/> salami picante,<br/> łososiem wędzonym
            </MDBCardText>
          </MDBCardBody>
            <MDBCardTitle>110 zł</MDBCardTitle>
        </MDBCard>
      </MDBCol>
   
    </MDBRow>
  );
}
export default CardSetMain;