import CardSetMain from "../components/CardSetMain";
import Menu from "../components/Menu";


const Sets = () => {
    return(
<>
<Menu/>
<CardSetMain/>
</>
    )
}
export default Sets;